@font-face {
    font-family: Circular-Medium;
    src: url("../../styles/fonts/Circular-Medium.otf") format("opentype");
  }

.miniBioContainer {
    display: flex;
    width: 100%;
    font-size: 16px;
    @media (max-width: 768px) {
      justify-content: center;
    }
    @media (max-width: 600px) {
      justify-content: center;
    }
  }

  .miniBio {
    font-family: Circular-Medium;
  }

  .rowC {
    justify-content: center;
  }