.contact {
    margin: auto;
    width: 50%;
    padding: 20px;
  
    @media (max-width: 768px) {
      width: 100%;
      padding: 10px;
      font-size: 17px;
      text-align: left;
    }
  }

  .card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    border-radius: 10px;
    font-size: 20px;
  }