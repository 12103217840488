.about {
    margin: auto;
    width: 60%;
    padding: 20px;
  
    @media (max-width: 768px) {
      width: 100%;
      font-size: 14px;
    }
  }
  
  .aboutTxt {
    padding-top: 30px;
    font-family: Circular-Black;
    font-size: 20px;
    @media (max-width: 768px) {
      font-size: 14px;
    }
  }

  .aboutUni {
    font-family: Circular-Black;
    font-size: 20px;
    @media (max-width: 768px) {
      font-size: 14px;
    }
  }

  .card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    border-radius: 10px;
    font-size: 20px;
  }