// :global(.ant-card-body) {
//   padding: 24px 24px 24px 24px;
// }

:global(.ant-menu-horizontal) {
  border-bottom: 0px;
}

:global(.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item) {
  font-size: 13px;
  padding: 0 !important;
  margin: 5px !important;
  color: gray;
}

:global(.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected) {
  color: black !important;
  border-bottom: 2px solid black !important;
}

:global(.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover) {
  color: black !important;
  border-bottom: 2px solid black !important;
}

@font-face {
  font-family: Circular-Black;
  src: url("../../styles/fonts/Circular-Black.otf") format("opentype");
}

.menu {
  font-family: Circular-Black;
}

:global(.ant-menu > ::after) {
  border-bottom: none !important;
}

@font-face {
  font-family: Circular-Medium;
  src: url("../../styles/fonts/Circular-Medium.otf") format("opentype");
}

.uni {
  font-family: Circular-Black;
  font-size: 20px;
  padding-top: 30px;

  @media (max-width: 768px) {
    font-size: 14px;
  }
}

.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 10px;
  font-size: 20px;
}
