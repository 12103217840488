@font-face {
  font-family: Circular-Medium;
  src: url("../../styles/fonts/Circular-Medium.otf") format("opentype");
}

.mainContainer {
  // padding-left: 100px;
  // padding-right: 50px;
  @media (max-width: 768px) {
    padding: 10px;
  }
  @media (max-width: 600px) {
    padding: 10px;
  }
  @media (max-width: 1024px) {
    padding: 10px;
  }
}

.rowC {
  justify-content: center;
}

.alldimension {
  width: 100%;
}


.image:hover .imageDescription {
  padding: 10px;
  opacity: 1;
}

.imageDescription {
  text-align: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s;
}

.imageContainer {
  padding: 3px;
}

.overlayStyles {
  position: "absolute";
  filter: "blur(1px)";
  transition: "opacity ease-in 1000ms";
}
