@font-face {
  font-family: Circular-Medium;
  src: url("../../styles/fonts/Circular-Medium.otf") format("opentype");
}

@font-face {
  font-family: Peace-Sans;
  src: url("../../styles/fonts/Peace-Sans.otf") format("opentype");
}

.header {
   float: right;
   padding-right: 50px;
   @media (max-width: 1024px) {
    float: none;
    padding-right: 0px;
  }
}

.container {
  padding-left: 195px;
  padding-right: 240px;
  padding-top: 100px;
  @media (max-width: 1024px) {
    padding: 20px;
  }
}

.images {
  padding-left: 50px;
  padding-bottom: 50px;
  @media (max-width: 768px) {
    padding-left: 0px;
    padding-bottom: 50px;
  }
}

.title {
  font-family: Peace-Sans;
  font-size: 20px;
  padding-bottom: 30px;
  border-bottom: 1px solid black;
  margin-bottom: 30px;
}

.description {
  font-size: 15px;
  font-family: Circular-Medium;
  padding-bottom: 30px;
}

:global(.anticon-rotate-right) {
  display: none;
}

:global(.anticon-rotate-left) {
  display: none;
}

@font-face {
  font-family: Circular-Black;
  src: url("../../styles/fonts/Circular-Black.otf") format("opentype");
}

@font-face {
  font-family: Circular-Medium;
  src: url("../../styles/fonts/Circular-Medium.otf") format("opentype");
}

